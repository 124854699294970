import { store } from "@/internal";
import { batchTypeService } from "@/services/batchType.service";
import { brandService } from "@/services/brand.service";
import { customerService } from "@/services/customer.service";
import HttpService from "@/services/http.service";
import { labResultsService } from "@/services/labResults.service";
import { messagesService } from "@/services/messages.service";
import { productService } from "@/services/product.service";
import { productCategoryService } from "@/services/productCategory.service";
import { strainService } from "@/services/strain.service";
import { vendorService } from "@/services/vendor.service";
import { PageNavAction } from "@/types/types";
import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
import Template from "./ImportWizard.template.vue";
import ResultView from "./resultView/ResultView.component";

export interface ImportResult {
  status: string;
  text: string;
}

@Component({
  mixins: [Template],
  components: {
    ResultView
  }
})
export default class ImportWizard extends Vue {
  public columns: { id?: object } = {};
  public path: string = "";
  public headers: string[] = [];
  public fileSelected: any = null;
  public fileToUpload: File | null = null;
  public requiredMissing: boolean = false;
  public loading: boolean = false;
  public invalidFileName: boolean = false;
  protected csvType: string = "";
  protected step: number = 1;
  protected result: ImportResult | null = null;
  @Action("setPageNav", { namespace: "PageNavModule" })
  protected setPageNav!: PageNavAction;

  protected csvTypeServices: { [key: string]: HttpService } = {
    "batch-types": batchTypeService,
    "product-categories": productCategoryService,
    brands: brandService,
    strains: strainService,
    "vendors-laboratories": vendorService,
    products: productService,
    customers: customerService,
    "lab-results": labResultsService
  };

  public fileChangeEvent(imagen: any) {
    if (!imagen) {
      this.fileSelected = null;
      return;
    }
    // getting file extension
    const fileExtension = imagen.target.files[0].name.match(/\.([^\.]+)$/)![1];
    // getting file size in "mega bytes" by dividing with 1000000
    const fileSize = imagen.target.files[0].size / 1000000;
    // only " alphanumeric,_ , - , ." characters are allowed in file name
    if (/([^a-zA-Z0-9_.\-])/.test(imagen.target.files[0].name)) {
      this.invalidFileName = true;
    } else {
      this.invalidFileName = false;
    }

    const invalidDocMsg: string | string[] = [];
    if (!["csv"].includes(fileExtension)) {
      invalidDocMsg.push(
        String(
          this.$t("security.invalid_file_extention", {
            fileExtention: "csv"
          })
        )
      );
    }
    if (fileSize > store.getters["AuthModule/fileUploadLimit"]) {
      invalidDocMsg.push(
        String(
          this.$t("security.invalid_file_size", {
            fileSize: store.getters["AuthModule/fileUploadLimit"]
          })
        )
      );
    }
    if (this.invalidFileName) {
      invalidDocMsg.push(String(this.$t("security.invalid_file_name")));
    }
    if (invalidDocMsg.length) {
      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        invalidDocMsg,
        "error"
      );
    } else {
      this.requiredMissing = false;
      this.fileToUpload = (imagen.target.files
        ? imagen.target.files[0]
        : imagen.dataTransfer!.files[0]) as File;
      const reader = new FileReader();
      reader.readAsDataURL(this.fileToUpload);
      reader.onloadend = () => {
        this.fileSelected = reader.result;
      };
      this.$emit("fileSelected", this.fileToUpload);
    }
  }

  public async next() {
    if (this.fileToUpload) {
      try {
        this.loading = true;
        this.result = await this.csvTypeServices[this.csvType].callCSV(
          "csv_import",
          this.fileToUpload
        );
      } catch (e) {
        if (e.response && e.response.status === 422) {
          this.result = e.response.data.data || e.response.data;
          this.result!.text = e.response.data.message || e.response.message;
        } else {
          this.result = null;
          messagesService.renderErrorMessage(e);
        }
      } finally {
        if (this.result) {
          this.step = 2;
        }
        this.loading = false;
      }
    } else {
      this.requiredMissing = true;
    }
  }

  public back() {
    this.$router.back();
  }

  public retry() {
    this.fileSelected = this.fileToUpload = null;
    this.path = "";
    this.step = 1;
  }

  public async downloadExampleCSV() {
    try {
      this.loading = true;
      const csv = await this.csvTypeServices[this.csvType].callCSV(
        "csv_example"
      );
      if (!csv) {
        return;
      }
      const filename = `${this.csvType}-example.csv`;

      const encoded = !csv.match(/^data:text\/csv/i)
        ? encodeURIComponent("\uFEFF" + csv)
        : csv;

      const link = document.createElement("a");
      link.setAttribute("href", "data:text/csv;charset=utf-8," + encoded);
      link.setAttribute("download", filename);
      link.click();
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      this.loading = false;
    }
  }

  get success() {
    return this.result && this.result.status === "done";
  }

  protected async mounted() {
    if (this.$route.params.type === "lab-results") {
      this.headers = [
        "column",
        "required",
        "type",
        "identifier",
        "UOM",
        "example_options"
      ];
    } else {
      this.headers = [
        "column",
        "required",
        "type",
        "identifier",
        "example_options"
      ];
    }
    this.csvType = this.$route.params.type;

    this.setPageNav({
      title: `${this.$t("importModule.import")} ${this.$t(
        `importModule.${this.csvType}`
      )}`,
      isLoading: () => this.loading,
      rightActions: {
        generalActions: () => [
          {
            icon: "fal fa-times",
            action: this.back
          }
        ]
      }
    });

    this.columns = await this.csvTypeServices[this.csvType].callCSV(
      "csv_fields"
    );
  }
}
